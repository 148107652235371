import {takeFirstDefinedForKeys} from 'powtoon-commons/utils'
import {getInitialWebVars} from 'powtoon-commons/utils/webvars'

const webvars = getInitialWebVars(({IS_PROD_ENV}) => IS_PROD_ENV)

const assignedWebVars = takeFirstDefinedForKeys({
  userName:                          [webvars.username, 'USER_NAME_IS_MISSING'],
  userId:                            [webvars.userid, null ],
  apiHost:                           [process.env.API_HOST, webvars.api_host, ''],
  isNewUser:                         [webvars.is_new_user, false],
  segment:                           [webvars.segment, 'work'],
  usecase:                           [webvars.use_case, ''],
  savingInterval:                    [webvars.saving_interval_sec, 120],
  cdnApiHost:                        [webvars.CDN_API_HOST, ''],
  startWithTemplateChooser:          [webvars.start_with_template_chooser, false],
  skipSBForEmptyTemplate:            [webvars.skip_sb_for_empty_template, false],
  goToEditorAfterTemplatePopulate:   [webvars.go_to_editor_after_template_populate, false],
  goToEditorAfterTemplateChosen:     [webvars.go_to_editor_after_template_chosen, false],
  studioReadyEventName:              [webvars.studioReadyEventName, 'studio-loading-finished'],
  setBasicSturctureForEmptyTemplate: [webvars.set_basic_structure_for_empty_template, true],
  contentPermissionId:               [webvars.content_permission_id, 'CONTENT_PERMISSION_ID_IS_MISSING'],
  isStoryboardHtmlStudio:            [webvars.is_storyboard_html_studio, false],
  show5LooksScreen:                  [webvars.show_5_looks_screen, false],
  startWithSquareMode:               [webvars.start_with_square_mode, false],
  abOptInTrial:                      [webvars.ab_opt_in_trial, false],
  trialTimerMode:                    [webvars.trial_timer_mode, 'default'],
  showMobileAppPromotion:            [webvars.enable_mobile_app_promotion, false],
  isProdEnv:                         [webvars.IS_PROD_ENV, false],
  isStagingEnv:                      [webvars.IS_STAGING_ENV, false],
  isEnterprise:                      [webvars.is_enterprise, false],
  enableOrgFeatures:                 [ webvars.enable_org_features, webvars.is_enterprise, false ],
  pusherKey:                         [process.env.PUSHER_KEY, webvars.pusher_key, 'PUSH_KEY_IS_MISSING'],
  logzioId:                          [webvars.logzio_id, null],
  logzioMetricsId:                   [webvars.logzio_metrics_id, 'LOGZIO_METRICS_ID_IS_MISSED'],
  logzioUrl:                         [webvars.logzio_url, 'https://listener.logz.io:8091'],
  min_log_level:                     [webvars.min_log_level, null],
  embeddedSessionId:                 [webvars.embedded_session_id, null],
  sessionId:                         [webvars.session_id, webvars.embedded_session_id, null ],
  hideNameField:                     [webvars.app_settings?.hide_name_field, null],
  hideUpgradeButton:                 [webvars.hide_upgrade_button, false],
  upgradeButtonExperiment:           [webvars.upgrade_button_experiment, 'upgrade_button_studio_top_right'],
  hideQuickMenu:                     [webvars.app_settings?.hide_quick_menu, null],
  customExportLabel:                 [webvars.app_settings?.custom_export_label, null],
  customPublishUrlFormat:            [webvars.app_settings?.custom_publish_url_format, null],
  disableExternalLinks:              [webvars.app_settings?.disable_external_links, null],
  arenaUrl:                          [webvars.arena_url, '/arena?action=auth'],
  enableArena:                       [webvars.enable_arena, false],
  powtoonContextMenuMode:            [webvars.app_settings?.powtoon_context_menu_mode, 'default'],
  enableWochit:                      [!!webvars.enable_wochit, false],
  wochitProviderId:                  [webvars.wochit_provider_id, null],
  appLevel:                          [webvars.APP_LEVEL, null],
  fontsUploadRequiredPlan:           [webvars.fonts_upload_required_plan, 'agency_yearly'],
  predefinedPlan:                    [webvars.predefined_plan, ''],
  enableFontsUpload:                 [webvars.enable_fonts_upload, false],
  abMode:                            [webvars.ab_mode, 'test', null],
  enableDynamicFeatures:             [webvars.enable_dynamic_features, false],
  clientIP:                          [webvars.client_ip, null],
  isDashPanelEnabled:                [webvars.enable_pt_features, false],
  isLocalEnv:                        [process.env.NODE_ENV === 'development', false],
  enableSoundEffect:                 [webvars.enable_sound_effect, false],
  enabledNewDashboard:               [webvars.enabled_new_dashboard, false],
  ecPublicLinkEnabled:               [webvars.public_link_enabled, true],
  enableWebCamRecord:                [webvars.enable_web_cam_record, true],
  showHelpMenuButton:                [webvars.app_settings?.show_help_menu_button, null],
  enableFPSMeter:                    [webvars.enable_fps_meter, false],
  fpsmeter_initDelay:                [webvars.fpsmeter_init_delay, 20],
  fpsmeter_maxLogsPerClient:         [webvars.fpsmeter_max_logs_per_client, 20],
  fpsmeter_maxLogsPerInterval:       [webvars.fpsmeter_max_logs_per_interval, 5],
  fpsmeter_intervalLengthSecs:       [webvars.fpsmeter_interval_length_secs, 600],
  fpsmeter_goodFPSThreshold:         [webvars.fpsmeter_good_fps_threshold, 50],
  fpsmeter_badFPSThreshold:          [webvars.fpsmeter_bad_fps_threshold, 10],
  fpsmeter_numberOfSecondsToLog:     [webvars.fpsmeter_number_of_seconds_to_log, 12],
  fpsmeter_minBadSequenceToReport:   [webvars.fpsmeter_min_bad_sequence_to_report, 4],
  fpsmeter_minGoodSequenceBeforeDrop: [webvars.fpsmeter_min_good_sequence_before_drop, 3],
  enableLockFeatures:                [webvars.enable_lock_features, false],
  enableMyCharactersFolder:          [webvars.enable_my_characters_folder, false],
  enableCharacterEdit:               [webvars.enable_character_edit, false],
  enableSlideList:                   [webvars.enable_slide_list, false],
  slideThumbUploadDelay:             [webvars.slide_thumb_upload_delay, 200],
  cdnSlideThumbPath:                 [webvars.cdn_slide_thumb_path, 'CDN_SLIDE_THUMB_PATH_IS_MISSING'],
  publishFlowUrl:                    [webvars.publish_flow_url, false],
  startExportFlowInsideStudio:       [webvars.start_export_flow_inside_studio, 'disabled'],
  shareUrlFormat:                    [webvars.share_url_format, 'SHARE_URL_FORMAT_IS_MISSING'],
  STCV:                              [webvars.slide_thumb_creation_version, '0000'],
  exportWithWatermarks:              [webvars.export_with_watermarks, false],
  loginSessionId:                    [webvars.login_session_id, null],
  enableUpgrades:                    [webvars.enable_upgrades, true],
  showCaptureElements:               [webvars.show_capture_elements, false],
  hideUserName:                      [webvars.app_settings?.hide_user_name, false],
  enableImageExport:                 [webvars.enable_image_export, true],
  enableGifExport:                   [webvars.enable_gif_export, true],
  pusherRegion:                      [webvars.pusher_region],
  useNcpMode:                        [webvars.use_ncp_mode, false],
  showPresentPreview:                [webvars.show_present_preview, true],
  captureExtensionStoreLink:         [webvars.capture_extension_store_link, 'https://chrome.google.com/webstore/detail/powtoon-capture-screen-an/hgphbmgpkindhnldhjafhfflhjodpfmn?hl=en'],
  captureExtensionId:                [webvars.capture_extension_id, 'hgphbmgpkindhnldhjafhfflhjodpfmn'],
  batchFontChange:                   [webvars.batch_font_change, false],
  enableIndividualSharing:           [webvars.enable_individual_sharing, false],
  enableShareLink:                   [webvars.app_settings?.enable_share_link, false],
  batchColorChange:                  [webvars.batch_color_change, false],
  showWellsaid:                      [webvars.show_wellsaid, false],
  filePickerApiKey:                  [webvars.file_picker_api_key, 'ASBRzh0ZOQei0YdvWj68Zz'],
  simpleABOnEditMode:                [webvars.simple_a_b_on_edit_mode, false],
  pluginsHostEnabled:                [webvars.enable_studio_plugins, false],
  showCreateBtn:                     [webvars.show_create_btn, false],
  enableMasterObject:                [webvars.enable_master_object, false],
  enableCompanyFonts:                [webvars.enable_company_fonts, false],
  orgTemplatesEnabled:               [webvars.org_templates_enabled, false],
  enabledUseSystemClipboard:         [webvars.enabled_use_system_clipboard, true],
  closedCaptionsEnabled:             [webvars.closed_captions_enabled, false],
  enableColorFiltering:              [webvars.enable_color_filtering, false],
  enableNativeEyeDropper:            [webvars.enable_native_eye_dropper, true],
  enableAdminLocks:                  [webvars.enable_admin_locks, false],
  enableFullRichText:                [webvars.enable_full_rich_text, false],
  viewOnlyModeEnabled:               [webvars.view_only_mode_enabled, false],
  sessionTimeoutInMinutesNotSharedPowtoon: [webvars.session_timeout_not_shared_powtoon, 60],
  sessionTimeoutInMinutesSharedPowtoon: [webvars.session_timeout_shared_powtoon, 20],
  enableLipSync:                     [webvars.enable_lipsync, false],
  enableTextToSpeechNewService:      [webvars.enable_text_to_speech_new_service, false],
  enableTextToSpeech:                [webvars.enable_text_to_speech, false],
  powtoonUpsellMode:                 [webvars.powtoon_upsell_mode, true],
  enableSvgFontThumbs:               [webvars.enable_svg_font_thumbs, true],
  enableCameraMovement:              [webvars.enable_camera_movement, false],
  colorFilteringRequiredPlan:        [webvars.color_filtering_required_plan, 'agency_yearly'],
  enableCompanyColorPalettes:        [webvars.enable_company_color_palettes, false],
  enableVideoSoundControl:           [webvars.enable_video_sound_control, false],
  licenseGeneration:                 [webvars.license_generation, null],
  enableRichTextLists:               [webvars.enable_rich_text_lists, false],
  editPowtoonFlow:                   [webvars.app_settings?.edit_powtoon_flow, null],
  enableSetFrameOnMedia:             [webvars.enable_set_frame_on_media, false],
  enableLibraryItemsMultiSelect:     [webvars.enable_library_items_multi_select, false],
  enableVideoTrimming:               [webvars.enable_video_trimming, false],
  enableCategoriesOnPosesMenu:       [webvars.enable_categories_on_poses_menu, false],
  canUseTtsPermissionDebug:          [webvars.can_use_tts_permission_debug, webvars.is_enterprise, false],
  enableWorkspaceDnd:                [webvars.enable_workspace_dnd, false],
  studioValidationMode:              [webvars.studio_validation_mode, 'webworker'], // webworker, default, disabled
  brandingStyle:                     [webvars.branding_style, 'regular'],
  hideAccountInfo:                   [webvars.app_settings?.hide_account_info, false],
  hideMoreMenu:                      [webvars.app_settings?.hide_more_menu, false],
  enableLicensedGroupInvitations:    [webvars.enable_licensed_group_invitations, false],
  enableInvitingGuests:              [webvars.enable_inviting_guests, false],
  enableScriptingTool:               [webvars.enable_scripting_tool, false],
  enableAiTools:                     [webvars.enable_ai_tools, false],
  enableAiScriptingTool:             [webvars.enable_ai_scripting_tool, false],
  enableExpressions:                 [webvars.enable_expressions, false],
  enableArenaTheme:                  [webvars.enable_arena_theme, false],
  enableExpressionsAnimations:       [webvars.enable_expressions_animations, false],
  enableTtsEdit:                     [webvars.enable_tts_edit, false],
  enableNewStudioToolbar:            [webvars.enable_new_studio_toolbar, false],
  enableLegacyTts:                   [webvars.enable_legacy_tts, false],
  enableFeatureTryouts:              [webvars.enable_feature_tryouts, false],
  enableRealisticAvatars:            [webvars.enable_realistic_avatars, false],
  enableWorkspaceWithLobbies:        [webvars.enable_workspace_with_lobbies, false],
  disableExternalLinks:              [webvars.app_settings?.disable_external_links, false],
  isLocalhost:                       [webvars.is_localhost, false],
  enableCreditSystem:                [webvars.enable_credit_system, false],
  paypalDeactivated:                 [webvars.paypal_deactivated, true],
  captchaRequired:                   [webvars.captcha_required, false]
})

export default {
  getWebVars: () => assignedWebVars,
  getWebVar: propName => assignedWebVars[propName]
}
